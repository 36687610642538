.posts-container{
    width: 95%;
    margin: 20px auto;
    display:grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap:10px;
}

.title-and-time{

    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.content{
    width: 60%;
    font-size: 18px;
}

.posts-box{
    width: 100%;
    padding: 10px 0;
    overflow:hidden;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.5);
}

.posts-img{
    width: 100%;    
    height: 185px;
    overflow: hidden;
    position: relative;
}

.posts-img img{
    display:block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.posts-img:hover img{
    transition: all 1s linear;
    filter: invert(1);
}

.posts-img .categories{
    position: absolute;
    left: 0;
    top: 10px;
    display:flex;
    gap: 5px;
}

.posts-img .categories .category{
    padding: 2px 10px;
    font-size: 0.9rem;
    color: #fff;
}

a{
    text-decoration: none;
}

.posts-text h3{
    font-size: 1.1rem;
    text-transform: capitalize;
    color: #000;
    font-weight: 500;   
    margin: 5px 0;
    line-height: 25px;
    transition: all 0.3s linear;
}

.posts-text h3:hover{
    color: #0101b7;
}

.posts-text .category-time{
    display:flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.9rem;
    margin-top:5px;
    font-weight: 500;
}

.posts-text .category-time .published-time{
    color:#888;
}

.posts-text .excerpt {
    font-size: 0.9rem;
    color: #888;
}

.search{
    width: 95%;
    margin: 20px auto;
    height: 50px;
    background: #fff;
    display:flex;
    align-items: center;
    justify-content: center;
}

.search input{
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    background:transparent;
    padding: 0 20px;
    font-size: 0.95rem;
}

.search button {
    border: none;
    outline: none;
    background: #182848;
    width: 80px;
    height: inherit;
    color: #fff;
    display:flex;
    align-items: center;
    justify-content: center;
    cursor:pointer;
    user-select: none;
}

.post-container{
    width: 95%;
    margin: 0 auto;
}

.post-container article{
    width: 100%;
    display:flex;
    flex-direction: column;
    align-items: center;
}

.post-container article .title{
    text-align: center;
    font-size: 2rem;
    text-transform: capitalize;
    color: #182848;
    margin-bottom: 10px;
}

.post-container article .published-time{
    text-align: center;
    margin-bottom: 20px;
    font-size: 1.2rem;
    color: #838eab;
}

.post-container article .published-time i{
    margin-right: 7px;
}

.post-container article .content {
    margin-bottom: 20px;
    max-width: 100%;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left:0;
}

.post-container article .content :is(img, video){
    max-width: 40vw;  
    max-height: 40vh; 
    object-fit: contain;
    margin: 10px 0;
}

.post-container article :is(h1, h2, h3, h4, h5, h6, p){
    margin: 10px 0;
    line-height: 1.8rem;
}

.comments{
    margin-top:50px;
    margin-bottom: 10px;
    width: 100%;
}

.comments h2{
    color: #4b6cb7;
    padding: 5px 0;
}

.comments form :is(input, textarea) {
    width: 100%;
    border: none;
    outline: none;
    background: #fff;
    padding: 10px 20px;
    font-size: 0.95rem;
    margin: 10px 0;
    resize: none;
}

.comments form input{
    height: 50px;
}

.comments form .check{
    display:flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
}

.comments form .check input[type="checkbox"]{
    width: 25px;
    height: 25px;
}

.comments .content{
    margin-left: 0;
}

.comments form button{
    display:block;
    border: none;
    outline: none;
    background: #4b6cd7;
    padding: 10px 30px;
    color: #fff;
    font-weight: 600;
    letter-spacing: 1px;
    cursor:pointer;
}

.comments .content{
    margin-top:20px;
    background: #4b6cb7;
    color: #fff;
    border-radius: 5px;
    padding: 10px 20px;
}

.comments .content i{
    margin-right: 5px;
}

.comments .content p{
    color: #ddd;
    font-size: 0.9rem;
    margin-top:3px;
}